import React from 'react';
import Icon1 from '../../images/services/sn-demo-service1.svg';
import Icon2 from '../../images/services/sn-demo-service2.svg';
import Icon3 from '../../images/services/sn-demo-service3.svg';
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>

        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Speech Evaluation</ServicesH2>
          <ServicesP>We can evaluate your speech problem by using AI speech therapist</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>24/7 Speech Training</ServicesH2>
          <ServicesP>You can access our platform online anywhere and anytime</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Speech Therapist</ServicesH2>
          <ServicesP> Contracted speech therapists also available for online therapy</ServicesP>
        </ServicesCard>

      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
