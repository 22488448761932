export const aboutObj = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'FONIQ',
  headline: 'What is FONIQ',
  description: 'Our (AI-assisted) speech therapists are available for online speech training. Using FONIQ on your smartphone anywhere and anytime you want',
  buttonLabel: 'Get started',
  imgStart: false,
  img: require('../../images/info/sn-demo1.png'),
  alt: 'Car',
  dark: false,
  primary: true,
  darkText: true
};

export const discoverObj = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '24/7 Online speech training',
  headline: 'The Best Way to Improve Your Speech Problem',
  description: 'Speaking practice with FONIQ is a lot of fun. As we have demonstrated, our five-minute lessons have a positive impact on patients',
  buttonLabel: 'Get started',
  imgStart: true,
  img: require('../../images/info/sn-demo2.png'),
  alt: 'Car',
  dark: false,
  primary: false,
  darkText: true
};

export const signupObj = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Our will power',
  headline: 'Improvement within one month',
  description: 'Create a new account unless you already have one, and enjoy our offerings',
  buttonLabel: 'Get started',
  imgStart: false,
  img: require('../../images/svg-3.svg'),
  alt: 'svg3',
  dark: false,
  primary: false,
  darkText: true
};
