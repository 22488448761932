import React from 'react';
import TeamPic1 from '../../images/team/ct_pic.png';
import TeamPic2 from '../../images/team/cb_pic.png';
import TeamPic3 from '../../images/team/pj_pic.png';
import TeamPic4 from '../../images/team/surat_pic.png';
import TeamPic5 from '../../images/team/kitti_pic.png';
import { TeamContainer, TeamH1, TeamWrapper,TeamWrapper2, TeamCard, TeamIcon, TeamH2, TeamP } from './TeamElements';

const Team = () => {
  return (
    <TeamContainer id='Team'>
      <TeamH1>Our Team</TeamH1>
      <TeamWrapper>

        <TeamCard>
          <TeamIcon src={TeamPic1} />
          <TeamH2>Chutithep Teekaput, MD</TeamH2>
          <TeamP>Neurologist/ Stroke Expert</TeamP>
        </TeamCard>

        <TeamCard>
          <TeamIcon src={TeamPic2} />
          <TeamH2>Chiraphat Boonnag, MD</TeamH2>
          <TeamP> Physician/ Developer</TeamP>
        </TeamCard>

        <TeamCard>
          <TeamIcon src={TeamPic3} />
          <TeamH2>Piyawan Jareontonyakorn</TeamH2>
          <TeamP>Speech therapist</TeamP>
        </TeamCard>
      </TeamWrapper>

      <TeamWrapper2>
        <TeamCard>
          <TeamIcon src={TeamPic4} />
          <TeamH2>Surat Tanprawate, MD</TeamH2>
          <TeamP> Neurologist/ Innovator</TeamP>
        </TeamCard>

        <TeamCard>
          <TeamIcon src={TeamPic5} />
          <TeamH2>Kitti Thiankhaw, MD</TeamH2>
          <TeamP>Neurologist/ Stroke Mentor</TeamP>
        </TeamCard>
        </TeamWrapper2>

      
    </TeamContainer>
  );
};

export default Team;
