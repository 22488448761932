import React from 'react';
import PartnersPic1 from '../../images/partner/logo-chivacare.png' ;
import PartnersPic2 from '../../images/partner/logo-medcmu.png' ;
import PartnersPic3 from '../../images/partner/logo-rise.png' ;
import PartnersPic4 from '../../images/partner/logo-smid.png' ;
import PartnersPic5 from '../../images/partner/logo-tcels.png' ;

import { PartnersContainer, PartnersH1, PartnersWrapper, PartnersCard, PartnersIcon, PartnersH2, PartnersP } from './PartnersElements';

const Partners = () => {
  return (
    <PartnersContainer id='Partners'>
      <PartnersH1>Our Partners</PartnersH1>
      <PartnersWrapper>


          <PartnersIcon src={PartnersPic1} />
          <PartnersIcon src={PartnersPic2} />
          <PartnersIcon src={PartnersPic3} />
          <PartnersIcon src={PartnersPic4} />
          <PartnersIcon src={PartnersPic5} />
          
      </PartnersWrapper>

    </PartnersContainer>
  );
};

export default Partners;
